import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/sOI1i8H1TtQ">
    <SEO title="Corporate Growth - Growth" />
  </Layout>
)

export default SermonPost
